import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { OutboundLink } from "gatsby-plugin-google-analytics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Utilities`}</h2>
    <ProjectCard title="Events and Content" link="https://discord.gg/geksworld" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  <center>As a holder of our NFTs you will have access to invite-only exclusive events
  hosted by <OutboundLink href="https://instagram.com/jenesisgiselle" mdxType="OutboundLink">@jenesisgiselle</OutboundLink>,
  with different artists, producers DJs and more. Network with music industry
  professionals, show off your mixtape and producing skills or just sip on 1942
  with your favorite artist.</center>
    </ProjectCard>
    <ProjectCard title="Charity" link="/terms" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  <center>The whitelist minting sales will go directly to charities supporting
  disadvantaged kids in Florida and elsewhere. Gek and Jen will personally
  sponsor and present donations supporting causes near to them with 100% of
  the whitelist sales being allocated for donations. Check the terms for more info.</center>
    </ProjectCard>
    <ProjectCard title="Giveaways" link="https://discord.gg/geksworld" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
   <center>Two prizes will be awarded to a public NFT holder, once 50% of NFTs are sold,
   one worth approximately $50,000 USD to a random holder with a special attribute
   and at 100% one worth approximately $100,000 to a lucky minter. *</center>
    </ProjectCard>
    <ProjectCard title="GeksWorld" link="/terms/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  <center>All members will get access to exclusive Merch, online, metaverse and in person
  functionality such as events, with random holders selected for celebrity meet
  and greets, backstage VIP passes and other exclusive experiences.
  Click here for Terms and Conditions</center>
    </ProjectCard>
    <p>{`*`}<OutboundLink href="/terms" mdxType="OutboundLink">{`Click here for Terms and Conditions`}</OutboundLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      