import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { OutboundLink } from "gatsby-plugin-google-analytics";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <center>
  <p>
    Holders will have access to exclusive in-person VIP events in cities all
    throughout the year, and premium membership and early access to special
    GeksWorld podcasts and other content.
  </p>
    </center>
    <center>
  <p>
    This NFT is a digital ticket to all of our private parties with special <span style={{
          "color": "pink"
        }}>guest artists</span>, <span style={{
          "color": "orange"
        }}>celebrities</span>, <span style={{
          "color": "green"
        }}>exclusive music</span>, <span style={{
          "color": "blue"
        }}>networking opportunities</span>, and <span style={{
          "color": "red"
        }}>insane giveaways</span> hosted by <OutboundLink href="https://instagram.com/jenesisgiselle" mdxType="OutboundLink">@jenesisgiselle</OutboundLink>
    </p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      