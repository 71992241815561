import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Text } from 'theme-ui';
import { OutboundLink } from "gatsby-plugin-google-analytics";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <center><h2> Logistics</h2></center>
    <Box p={4} color="white" bg="primary" mdxType="Box">
  <Text sx={{
        fontSize: 3
      }} mdxType="Text"><center>Geks World NFT Club is a collection of 10,000 Iconic Gekyume Artworks, which provides
  holders with membership and access to exclusive events, content, and meetups
  with up and coming artists and celebrity VIPs where you can mingle and party
  with the stars.</center>
  </Text>
    </Box>
    <br />
    <Box p={4} color="white" bg="secondary" mdxType="Box">
  <Text sx={{
        fontSize: 3
      }} mdxType="Text"><center>Geks World NFTs are ERC-721 tokens stored on the Ethereum blockchain and stored
  in the interplanetary filesystem (IPFS). Holding Gek World NFTs entitles you to our ERC-20 GEKKEN
  tokens, which you can use to buy exclusive merch, upgrades, and added experiences.</center>
  </Text>
    </Box>
    <br />
    <Box p={4} color="white" bg="primary" mdxType="Box">
  <Text sx={{
        fontSize: 3
      }} mdxType="Text"><center>Our mission is to bridge the metaverse and reality by connecting artists and
  fans via members only events and parties throughout the world, VIP sections
  at music events, exclusive access to podcasts, episodes and release parties.</center>
  </Text>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      