import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Grid, Box } from "theme-ui";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faDiscord, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Socials`}</h2>
    <br />
    <center>
      <Grid width={[128, null, 192]} mdxType="Grid">
  <Box mdxType="Box">
    <OutboundLink href="https://instagram.com/geksworldnft" mdxType="OutboundLink">
      @GeksWorldNFT
      <h1><FontAwesomeIcon icon={faInstagram} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
  <Box mdxType="Box">
    <OutboundLink href="https://discord.gg/gekclub/" mdxType="OutboundLink">
      Discord
      <h1><FontAwesomeIcon icon={faDiscord} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
  <Box mdxType="Box">
    <OutboundLink href="https://twitter.com/gekclub/" mdxType="OutboundLink">
      Twitter
      <h1><FontAwesomeIcon icon={faTwitter} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
      </Grid>
    </center>
    <h2>{`Jenesis`}</h2>
    <br />
    <center>
      <Grid width={[128, null, 192]} mdxType="Grid">
  <Box mdxType="Box">
    <OutboundLink href="https://instagram.com/jenesisgiselle" mdxType="OutboundLink">
      @JenesisGiselle
      <h1><FontAwesomeIcon icon={faInstagram} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
  <Box mdxType="Box">
    <OutboundLink href="https://www.youtube.com/channel/UC2TAxgHoRvNw3GfwflsctvA" mdxType="OutboundLink">
      YouTube
      <h1><FontAwesomeIcon icon={faYoutube} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
  <Box mdxType="Box">
    <OutboundLink href="https://www.tiktok.com/@thejenesisgiselle" mdxType="OutboundLink">
      TikTok
      <h1><FontAwesomeIcon icon={faTiktok} mdxType="FontAwesomeIcon" /></h1>
    </OutboundLink>
  </Box>
      </Grid>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      